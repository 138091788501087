import CustomerDetailsBox from '../../pages/customer-details/CustomerDetailsBox';
import styles from './risk-graphs.module.scss';
import { ReactComponent as GraphIcon } from '../../assets/images/svg/graph.svg';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';
import { numberToPrice } from '../../utils/billing/plan-formatter';
import { maybePlural } from '../../utils/billing/utils';

const COLORS = [
  '#e60049', '#0bb4ff', '#1aa354', '#e6d800', '#9b19f5', '#ffa300', '#dc0ab4', '#526885', '#00bfa0', '#ad0397', '#0d0699', '#29283d'
];

const RiskGraphs = ({ addressActions, addressCounterparty }) => {
  const someGraphAvailable = [
    ...(addressActions?.received_txs ?? []),
    ...(addressActions?.spent_txs ?? []),
    ...(addressCounterparty ?? [])
  ].length > 0;

  if (!someGraphAvailable) {
    return null;
  }

  return <CustomerDetailsBox title='Activity breakdown' icon={<GraphIcon />}>
    <div className={styles.riskGraphsContainer}>
      {addressActions.received_txs.length > 0 && <div className={styles.chartWithTitleContainer}>
        <div className={styles.chartTitle}>Received transactions</div>
        <PieChart width={400} height={400}>
          <Pie
            data={addressActions?.received_txs ?? []}
            dataKey='proportion'
            nameKey='action'
            cx='50%'
            cy='50%'
            outerRadius={120}
            fill='#8884d8'
            label={({ percent }) =>
              `${(percent * 100).toFixed(2)}%`
            }
            labelLine={false}
          >
            {(addressActions?.received_txs ?? []).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name, props) => [
              `${props.name}: ${props.payload.payload.count} ${maybePlural('transaction', props.payload.payload.count > 1)} (${value}%)`,
            ]}
          />
          <Legend/>
        </PieChart>
      </div>}

      {addressActions.spent_txs.length > 0 && <div className={styles.chartWithTitleContainer}>
        <div className={styles.chartTitle}>Spent transactions</div>
        <PieChart width={400} height={400}>
          <Pie
            data={addressActions?.spent_txs ?? []}
            dataKey='proportion'
            nameKey='action'
            cx='50%'
            cy='50%'
            outerRadius={120}
            fill='#8884d8'
            label={({ percent }) =>
              `${(percent * 100).toFixed(2)}%`
            }
            labelLine={false}
          >
            {(addressActions?.spent_txs ?? []).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name, props) => [
              `${props.name}: ${props.payload.payload.count} ${maybePlural('transaction', props.payload.payload.count > 1)} (${value}%)`,
            ]}
          />
          <Legend/>
        </PieChart>
      </div>}

      {addressCounterparty.length > 0 && <div className={styles.chartWithTitleContainer}>
        <div className={styles.chartTitle}>Volume by counterparty (USD)</div>
        <PieChart width={500} height={400}>
          <Pie
            data={addressCounterparty ?? []}
            dataKey='percent'
            nameKey='name'
            cx='50%'
            cy='50%'
            outerRadius={120}
            fill='#8884d8'
            label={({ percent }) =>
              percent > 1 ? `${percent.toFixed(2)}%` : ''
            }
            labelLine={false}
          >
            {(addressCounterparty ?? []).map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
            ))}
          </Pie>
          <Tooltip
            formatter={(value, name, props) => [
              `${props.name}: ${numberToPrice(props.payload.payload.amount, 'usd')} volume (${value}%)`,
            ]}
          />
          <Legend/>
        </PieChart>
      </div>}
    </div>
  </CustomerDetailsBox>;
};

export default RiskGraphs;
