import styles from './add-destination-modal.module.scss';
import MainModal from '../../modals/MainModal';
import Select from '../../select/Select';
import { useContext, useState } from 'react';
import MainButton from '../../buttons/MainButton';
import { ReactComponent as BoltGreen } from '../../../assets/images/svg/boltGreenIcon.svg';
import useDeposits from '../../../services/deposits/deposits-service';
import toast from 'react-hot-toast';
import { axiosErrorHandler } from '../../../services/api/axios';

const SUPPORTED_CURRENCIES = ['BTC', 'ETH', 'USDT'];

const NETWORKS_BY_CURRENCY = {
  'BTC': [
    'BITCOIN',
  ],
  'ETH': [
    'ETHEREUM',
  ],
  'USDT': [
    'ETHEREUM',
  ],
};

const AddDestinationModal = ({ handleClose, projectId, afterAdd }) => {
  const { addDepositDestination } = useDeposits();

  const [currency, setCurrency] = useState('BTC');
  const [network, setNetwork] = useState('BITCOIN');
  const [address, setAddress] = useState('');
  const [beneficiary, setBeneficiary] = useState('');
  const [country, setCountry] = useState('');
  const [isInstitution, setIsInstitution] = useState(false);

  const handleCurrencyChange = (clickedOption) => {
    setCurrency(prevCurrency => {
      if (prevCurrency !== clickedOption.label) {
        setNetwork(NETWORKS_BY_CURRENCY[clickedOption.label][0]);
      }
      return clickedOption.label;
    });
  };

  const handleSubmit = async (e) => {
    const form = e.target.closest('form');
    if (!form.reportValidity()) {
      return;
    }
    e.preventDefault();

    const destinationData = {
      currency: currency,
      network: network,
      addresses: [address],
      travel_rule_is_self: false,
      travel_rule_beneficiary_name: beneficiary,
      travel_rule_country: country,
      travel_rule_wallet_type: 'WALLET_TYPE_SELF_HOSTED',
      travel_rule_institution: isInstitution ? '8e538030-e2f2-4db0-bc80-95d6d280d36b' : undefined,
    };

    try {
      const response = await addDepositDestination(projectId, destinationData);
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error adding the destination, please try again later'));
      return;
    }
    afterAdd(destinationData);
    toast.success('Destination added successfully');
    handleClose();
  };

  return <MainModal handleClose={handleClose}>
    <div className={styles.container}>
      <div className={styles.heading}>
        Add deposit destination
      </div>
      <form>
        <div>
          <div className={styles.label}>Currency:</div>
          <Select
            options={SUPPORTED_CURRENCIES.map(ticker => ({ label: ticker }))}
            value={currency}
            onOptionClick={handleCurrencyChange}
            selectClassName={styles.noMargin}
          />
        </div>
        <div>
          <div className={styles.label}>Network:</div>
          <Select
            options={NETWORKS_BY_CURRENCY[currency].map(network => ({ label: network }))}
            value={network}
            onOptionClick={clickedOption => setNetwork(clickedOption.label)}
            selectClassName={styles.noMargin}
          />
        </div>
        <div>
          <div className={styles.label}>Blockchain address:</div>
          <input
            required
            type='text'
            placeholder='0x62abb106fce9647bdf1e7877bf73ce8b0bad5316'
            className={styles.input}
            onChange={e => setAddress(e.target.value)}
          />
        </div>
        <div>
          <div className={styles.label}>Beneficiary name:</div>
          <input
            required
            type='text'
            placeholder='John Doe / Institution inc.'
            className={styles.input}
            onChange={e => setBeneficiary(e.target.value)}
          />
        </div>
        <div>
          <div className={styles.label}>Beneficiary country code:</div>
          <input
            required
            type='text'
            placeholder='FR'
            className={styles.input}
            onChange={e => setCountry(e.target.value)}
          />
        </div>
        <div className={styles.checkboxWithLabelContainer}>
          <input type='checkbox' checked={isInstitution} onChange={e => setIsInstitution(e.target.checked)}/>
          <div className={styles.label}>Beneficiary is institution</div>
        </div>
        <div>
          <MainButton
            onClick={handleSubmit}
            variant='button-black'
            label='Submit'
            icon={<BoltGreen/>}
          />
        </div>
      </form>
    </div>
  </MainModal>;
};

export default AddDestinationModal;
