import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import {
  determineTxAcceptance,
  getSimpleTransactionFee,
  getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx,
  maybeAddMisttrackRisks,
} from '../../../../utils/account-tx-parser';

const DOGE_CURRENCY_FACTOR = 100000000;

const createTatumTxIterator = (address) => {
  let txsToProcessBuffer = [];
  let txsToProcessPointer = 0;
  let hasNextFlag = true;
  let offset = 0;
  const pageSize = 50;

  const next = async () => {
    if (txsToProcessPointer < txsToProcessBuffer.length) {
      const tx = txsToProcessBuffer[txsToProcessPointer];
      txsToProcessPointer += 1;
      return tx;
    }

    const txsUrl = `https://api.tatum.io/v3/dogecoin/transaction/address/${address}`;
    const newTxsForBufferResponse = await axios.get(
      txsUrl,
      {
        params: {
          pageSize,
          offset,
        }
      }
    );
    const newTxsForBuffer = newTxsForBufferResponse?.data;
    if (newTxsForBuffer === undefined || newTxsForBuffer.length === 0) {
      hasNextFlag = false;
      return;
    }

    offset += pageSize;

    txsToProcessPointer = 1;
    txsToProcessBuffer = newTxsForBuffer;
    return txsToProcessBuffer[0];
  };

  const hasNext = () => {
    return hasNextFlag;
  };

  const reset = () => {
    offset = 0;
    txsToProcessBuffer = [];
    txsToProcessPointer = 0;
    hasNextFlag = true;
  };

  return { next, hasNext, reset };
};

const getDogeTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  externalApiKey = undefined, // Tatum API key
  fromToCombinator = { allHaveToMatch: false, orderMatters: true },
  loaderKey,
}) => {
  let nextPageExists = true;
  const dogeCurrencyPair = `doge/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};

  const { next: nextTatumTx, hasNext: hasNextTatumTx, reset: resetTatumIterator } = createTatumTxIterator(address);

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([dogeCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['doge'] = rates?.[dogeCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    let accountTransactions = [];

    while (accountTransactions.length < pageSize) {
      if (!hasNextTatumTx()) {
        nextPageExists = false;
        break;
      }
      const tatumTx = await nextTatumTx();
      if (tatumTx === undefined) {
        nextPageExists = false;
        break;
      }

      const accountTx = transformToAccountTx(tatumTx, address, accountId, exchangeRates, currency);
      const acceptThisTx = determineTxAcceptance(accountTx, fromAddress, toAddress, fromToCombinator);

      if (acceptThisTx) {
        accountTransactions.push(accountTx);
      }
    }

    return await maybeAddMisttrackRisks({
      accountTransactions,
      address,
      apiUrl,
      withMisttrackRisks,
      chain: 'doge',
      jwt: userJwt,
    });
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    nextPageExists = true;
    resetTatumIterator();
  };

  const getAllowedFilters = () => {
    return [];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const getCreatedAt = (tatumTx) => {
  const epochTime = tatumTx?.time;
  return new Date(epochTime * 1000);
};

// TODO rewrite for doge@tatum
const transformToAccountTx = (tatumTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'DOGE';

  // Beware, values in tatumTx are floats represented as strings
  let type = 'transfer';
  const totalOwnInValue = (tatumTx?.inputs ?? [])
    .filter(input => input?.coin?.address === originatorAddress)
    .reduce((acc, input) => acc + Number(input?.coin?.value ?? 0), 0);
  const totalOwnOutValue = (tatumTx?.outputs ?? [])
    .filter(output => output.address === originatorAddress)
    .reduce((acc, output) => acc + Number(output?.value ?? 0), 0);
  if (totalOwnInValue > totalOwnOutValue) {
    type = 'crypto withdrawal';
  } else if (totalOwnInValue < totalOwnOutValue) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(tatumTx),
    transaction_id: tatumTx.hash,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  (tatumTx?.inputs ?? []).forEach(input => {
    const inputMovement = {
      address: input?.coin?.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: Number(input?.coin?.value ?? 0), // Float represented as string in the response
      fee: false,
      owned: input?.coin?.address === originatorAddress,
    };
    accountTransaction.inputs.push(inputMovement);
  });

  (tatumTx?.outputs ?? []).forEach(output => {
    const outputMovement = {
      address: output?.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: Number(output?.value ?? 0), // Float represented as string in the response
      fee: false,
      owned: output?.address === originatorAddress,
    };
    accountTransaction.outputs.push(outputMovement);
  });

  // Add fee
  const fee = Number(tatumTx?.fee ?? 0);
  // It does not matter if this is not correct in this case, ignore fee movements anyway
  const feePayerAddress = tatumTx.inputs[0]?.coin?.address;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};

export default getDogeTxsLoader;
