import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../../context/user/UserContext';
import { valueExists } from '../../utils/common';
import { axiosErrorHandler } from '../api/axios';
import { toast } from 'react-hot-toast';
import { endpoints } from '../api/axios';

const useDeposits = () => {
  const { getConfig } = useContext(UserContext);
  
  const getPayouts = async (projectId, jwt, since, until) => {
    try {
      const response = await axios.get(
        '/v1/exchange/deposit/merchant-payouts',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
          params: {
            since,
            until,
          },
        }
      );
      return response?.data;
    } catch (err) {
      toast.error(axiosErrorHandler(err));
      return [];
    }
  };

  const getDeposits = async (projectId, jwt, since, until, userId = undefined) => {
    const params = {};
    if (valueExists(since)) {
      params.since = since;
    }
    if (valueExists(until)) {
      params.until = until;
    }
    if (valueExists(userId)) {
      params['user_id'] = userId;
    }
    try {
      const response = await axios.get(
        '/v1/exchange/deposit/universal/list',
        {
          baseURL: getConfig('api_url'),
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
          params,
        }
      );
      return response?.data;
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error getting the list of deposits, please try again later'));
      return [];
    }
  };

  const getProjectDepositDestinations = async (projectId) => {
    try {
      const response = await axios.get(
        `${endpoints.DEPOSIT_URL}/destinations`,
        {
          params: {
            project_id: projectId,
          }
        }
      );
      return response?.data;
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error getting current deposit destinations, please try again later'));
      return [];
    }
  };

  const addDepositDestination = async (projectId, destinationData) => {
    const params = {
      project_id: projectId,
    };
    const response = await axios.post(
      `${endpoints.DEPOSIT_URL}/destinations`,
      destinationData,
      {
        params,
      }
    );
    return response?.data;
  };

  const deleteDepositDestination = async (projectId, currency, network) => {
    const params = {
      project_id: projectId,
      currency,
      network,
    };
    const response = await axios.delete(
      `${endpoints.DEPOSIT_URL}/destinations`,
      { params }
    );
    return response?.data;
  };

  return {
    getPayouts,
    getDeposits,
    getProjectDepositDestinations,
    addDepositDestination,
    deleteDepositDestination,
  };
};

export default useDeposits;
