import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import { sleep, valueExists } from '../../../../utils/common';
import { filterTypes } from '../../../../components/filters/ConsolidatedTransactionsFilters';
import {
  determineTxAcceptance,
  getSimpleTransactionFee,
  getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx,
  maybeAddMisttrackRisks
} from '../../../../utils/account-tx-parser';

// TODO test this now
const getTonTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  fromToCombinator = { allHaveToMatch: false, orderMatters: true },
  externalApiKey = undefined,  // Toncenter API key, but general name to conform to loaders interface (virtual)
}) => {
  let nextPageExists = true;
  const tonCurrencyPair = `ton/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};
  let oldestFetchedLogicalTime = undefined;

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([tonCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['ton'] = rates?.[tonCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;

      if (valueExists(accountsFilterParam)) {
        if (!accountsFilterParam.split(',').includes(accountId)) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
      if (valueExists(digitalAssetsFilterParam)) {
        if (!digitalAssetsFilterParam.split(',').includes('TON')) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
    }

    const page = []; // Due to from/to filtering, multiple requests might be needed to fill a single page
    let remainingRequests = 100; // So that we don't run out of credits. This is 100 * 100 = 10k txs
    const url = 'https://toncenter.com/api/v2/getTransactions';

    while (page.length < pageSize) {
      const params = {
        api_key: externalApiKey,
        address: address,
        limit: 100,
      };
      if (valueExists(oldestFetchedLogicalTime)) {
        params.to_lt = oldestFetchedLogicalTime - 1; // Logical time of the last tx
      }

      let data = [];
      let wasError;
      try {
        wasError = false;
        const response = await axios.get(
          url,
          { params }
        );
        data = response?.data ?? [];
      } catch (err) {
        await sleep(2000);
        wasError = true;
      }

      if (!data?.ok) {
        await sleep(2000);
        wasError = true;
      }

      const fetchedToncenterTxs = data?.result ?? [];

      if (fetchedToncenterTxs.length < pageSize) {
        nextPageExists = false;
      }

      // Do not break if there was an error (retry instead)
      if (fetchedToncenterTxs.length === 0 && !wasError) {
        break;
      }

      // Move cursor
      if (fetchedToncenterTxs.length > 0) {
        const oldestLogicalTime = fetchedToncenterTxs.slice(-1)?.[0]?.transaction_id?.lt;
        if (oldestLogicalTime) {
          oldestFetchedLogicalTime = oldestLogicalTime;
        }
      }

      const logicalTimeByTxId = {};
      fetchedToncenterTxs.forEach(toncenterTx => {
        logicalTimeByTxId[toncenterTx.transaction_id.hash] = toncenterTx.transaction_id.lt;
      });

      const accountTransactions = data
        .map(toncenterTx => transformToAccountTx(toncenterTx, address, accountId, exchangeRates, currency))
        .filter(accountTx => determineTxAcceptance(accountTx, fromAddress, toAddress, fromToCombinator));

      for (const tx of accountTransactions) {
        if (page.length < pageSize) {
          page.push(tx);
        } else {
          oldestFetchedLogicalTime = logicalTimeByTxId[tx.transaction_id];
          nextPageExists = true;
          break;
        }
      }

      remainingRequests -= 1;
      if (remainingRequests <= 0) {
        break;
      }
    }

    return maybeAddMisttrackRisks({
      accountTransactions: page,
      address,
      apiUrl,
      withMisttrackRisks,
      chain: 'ton',
      jwt: userJwt,
    });
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    oldestFetchedLogicalTime = undefined;
    nextPageExists = true;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const getCreatedAt = (transaction) => {
  const epochTime = transaction.utime;
  return new Date(epochTime * 1000);
};

const transformToAccountTx = (toncenterTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  originatorAddress = originatorAddress.toLowerCase();
  const TON_CURRENCY_FACTOR = 1000000000;
  const currency = 'TON';

  let type = 'transfer';
  const fromLower = toncenterTx?.in_msg.source.toLowerCase();
  const toLower = toncenterTx?.in_msg.destination.toLowerCase();
  if (toncenterTx?.in_msg.value !== '0') {
    if (fromLower === originatorAddress && toLower !== originatorAddress) {
      type = 'crypto withdrawal';
    } else if (fromLower !== originatorAddress && toLower === originatorAddress) {
      type = 'crypto deposit';
    }
  }

  const accountTransaction = {
    created_at: getCreatedAt(toncenterTx),
    transaction_id: toncenterTx.transaction_id.hash,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  const amount = (Number(toncenterTx.in_msg.value ?? '0')) / TON_CURRENCY_FACTOR; // TODO is this value correct?

  const inputMovement = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const outputMovement = {
    address: toLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: toLower === originatorAddress,
  };

  accountTransaction.inputs.push(inputMovement);
  accountTransaction.outputs.push(outputMovement);

  // Add fee
  const fee = Number(toncenterTx?.fee);

  const feeInput = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};

export default getTonTxsLoader;
