import axios from 'axios';
import { addMisttrackRiskToTxs } from './misttrack';
import { getAddressesToRiskExplore, handleQuickRiskError } from './quick-risk';
import { valueExists } from './common';

export const getSimpleTransactionFee = (accountTransaction, fee = null, currency) => {
  if (fee === null) {
    let sumInput = accountTransaction.inputs.reduce((sum, txInput) => sum + txInput.value, 0);
    let sumOutput = accountTransaction.outputs.reduce((sum, txOutput) => sum + txOutput.value, 0);
    fee = sumInput - sumOutput;
  }
  return getSimpleNonNegativeCurrencyValue(fee, currency);
};

const getSimpleNonNegativeCurrencyValue = (value, currency) => {
  return [{
    value: value,
    currency: currency,
    currencyName: currency,
    currencySymbol: currency,
  }];
};

export const getTransactionTotal = (accountTransaction) => {
  let total = [];
  accountTransaction.inputs.forEach(txInput => {
    total = sumMovement(total, txInput, true);
  });
  return total;
};

export const getTransactionAmount = (accountTransaction) => {
  let sumOutputsOwned = [];
  accountTransaction.outputs.forEach(txOutput => {
    if (txOutput.owned) {
      sumOutputsOwned = sumMovement(sumOutputsOwned, txOutput, false);
    }
  });

  let sumInputsOwned = [];
  accountTransaction.inputs.forEach(txInput => {
    if (txInput.owned) {
      sumInputsOwned = sumMovement(sumInputsOwned, txInput, false);
    }
  });

  return subCurrencyValues(sumOutputsOwned, sumInputsOwned);
};

const sumMovement = (currencyValues, movement, isTotal) => {
  for (let currencyValue of currencyValues) {
    if (currencyValue.currency === movement.currency) {
      let isToken = currencyValue.currency.split(':').length >= 2;
      if (!isTotal || !isToken) {
        currencyValue.value += movement.value;
      }
      return currencyValues;
    }
  }
  currencyValues.push({
    value: movement.value,
    currency: movement.currency,
    currencyName: movement.currency_name,
    currencySymbol: movement.currency_symbol,
  });
  return currencyValues;
};

const subCurrencyValues = (nonNegativeCurrencyValues1, nonNegativeCurrencyValues2) => {
  const currencyValues1 = nonNegativeCurrencyValues1.map(value => ({ ...value }));
  const currencyValues2 = nonNegativeCurrencyValues2.map(value => ({ ...value }));

  currencyValues2.forEach(currencyValue2 => {
    const match = currencyValues1.find(currencyValue1 => currencyValue1.currency === currencyValue2.currency);
    if (match) {
      match.value -= currencyValue2.value;
    } else {
      currencyValues1.push({ ...currencyValue2, value: -currencyValue2.value });
    }
  });

  return currencyValues1;
};

export const maybeAddConvertedValuesToTx = (accountTx, exchangeRates, currency, fiatCurrency) => {
  const rate = exchangeRates[currency.toLowerCase()];

  const convertMovement = (movement) => {
    if (movement.currency.toLowerCase() === currency.toLowerCase()) {
      return {
        ...movement,
        converted_value: movement.value * rate,
        converted_currency: fiatCurrency,
      };
    }
    return movement;
  };

  if (rate !== undefined) {
    accountTx.inputs = accountTx.inputs.map(convertMovement);
    accountTx.outputs = accountTx.outputs.map(convertMovement);
  }
};

export const maybeAddMisttrackRisks = async ({ accountTransactions, address, apiUrl, withMisttrackRisks, jwt, chain }) => {
  if (!withMisttrackRisks) {
    return {
      nextPage: accountTransactions,
      loadError: undefined,
    };
  }

  try {
    const addressesToExplore = getAddressesToRiskExplore(address, chain);
    let responseData;
    // Select the one with the highest misttrack risk score
    for (const addressToCheck of addressesToExplore) {
      const response = await axios.get(
        '/v1/quick_risk',
        {
          baseURL: apiUrl,
          headers: {
            'Authorization': `Bearer ${jwt}`,
          },
          params: {
            chain: chain,
            address: addressToCheck,
            metrics: 'riskResult',
          },
        }
      );
      if (responseData === undefined || response?.data?.risk_result?.score > (responseData?.risk_result?.score ?? 0)) {
        responseData = response.data;
      }
    }

    const riskDetail = responseData.risk_result?.risk_detail ?? [];
    const txsWithCustomRisk = addMisttrackRiskToTxs(accountTransactions, riskDetail, chain);
    return {
      nextPage: txsWithCustomRisk,
      loadError: undefined,
    };
  } catch (err) {
    handleQuickRiskError(err);
    return {
      nextPage: [],
      loadError: undefined,
    };
  }
};

const processAddress = (address) => {
  if (address.includes(':')) {
    // For bitcoin cash (bitcoincash:address, bchreg:address, ...)
    return address.split(':')[1].toLowerCase();
  }
  return address.toLowerCase();
};

export const determineTxAcceptance = (
  accountTx,
  fromAddress,
  toAddress,
  fromToCombinator = { allHaveToMatch: true, orderMatters: true }
) => {
  const valueWasTransferred = accountTx.outputs.reduce((valueFound, output) => {
    if (output.fee) {
      return valueFound;
    }
    return valueFound || (output.value !== 0);
  }, false);

  if (!valueWasTransferred) {
    return false;
  }

  fromAddress = processAddress(fromAddress).toLowerCase();
  toAddress = processAddress(toAddress).toLowerCase();

  const fromAddressSends = (!valueExists(fromAddress)) || accountTx.inputs.some(input => processAddress(input.address) === fromAddress);
  const toAddressReceives = (!valueExists(toAddress)) || accountTx.outputs.some(output => processAddress(output.address) === toAddress);
  const fromAddressReceives = (!valueExists(fromAddress)) || accountTx.outputs.some(output => processAddress(output.address) === fromAddress);
  const toAddressSends = (!valueExists(toAddress)) || accountTx.inputs.some(input => processAddress(input.address) === toAddress);

  let acceptThisTx;
  if (fromToCombinator.allHaveToMatch) {
    acceptThisTx = fromAddressSends && toAddressReceives;
  } else {
    acceptThisTx = fromAddressSends || toAddressReceives;
  }
  if (!fromToCombinator.orderMatters && !acceptThisTx) {
    if (fromToCombinator.allHaveToMatch) {
      acceptThisTx = fromAddressReceives && toAddressSends;
    } else {
      acceptThisTx = fromAddressReceives || toAddressSends;
    }
  }
  return acceptThisTx;
};
