import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import {
  determineTxAcceptance,
  getSimpleTransactionFee,
  getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx,
  maybeAddMisttrackRisks,
} from '../../../../utils/account-tx-parser';

const LTC_CURRENCY_FACTOR = 100000000;

const createLitecoinspaceTxIterator = (address) => {
  let txsToProcessBuffer = [];
  let txsToProcessPointer = 0;
  let hasNextFlag = true;
  let oldestFetchedTxId = undefined;

  const next = async () => {
    if (txsToProcessPointer < txsToProcessBuffer.length) {
      const tx = txsToProcessBuffer[txsToProcessPointer];
      txsToProcessPointer += 1;
      return tx;
    }

    let url = `https://litecoinspace.org/api/address/${address}/txs/chain`;
    if (oldestFetchedTxId) {
      url = `${url}/${oldestFetchedTxId}`;
    }
    const newTxsForBufferResponse = await axios.get(url);
    const newTxsForBuffer = newTxsForBufferResponse?.data;
    if (newTxsForBuffer === undefined || newTxsForBuffer.length === 0) {
      hasNextFlag = false;
      return;
    }

    oldestFetchedTxId = newTxsForBuffer.slice(-1)?.[0]?.txid;

    txsToProcessPointer = 1;
    txsToProcessBuffer = newTxsForBuffer;
    return txsToProcessBuffer[0];
  };

  const hasNext = () => {
    return hasNextFlag;
  };

  const reset = () => {
    oldestFetchedTxId = undefined;
    txsToProcessBuffer = [];
    txsToProcessPointer = 0;
    hasNextFlag = true;
  };

  return { next, hasNext, reset };
};

const getLtcTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  externalApiKey = undefined,
  fromToCombinator = { allHaveToMatch: true, orderMatters: true },
  loaderKey,
}) => {
  let nextPageExists = true;
  const ltcCurrencyPair = `ltc/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};

  const { next: nextLitecoinspaceTx, hasNext: hasNextLitecoinspaceTx, reset: resetLitecoinspaceIterator } = createLitecoinspaceTxIterator(address);

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([ltcCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['ltc'] = rates?.[ltcCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    let accountTransactions = [];

    while (accountTransactions.length < pageSize) {
      if (!hasNextLitecoinspaceTx()) {
        nextPageExists = false;
        break;
      }
      const litecoinspaceTx = await nextLitecoinspaceTx();
      if (litecoinspaceTx === undefined) {
        nextPageExists = false;
        break;
      }

      const accountTx = transformToAccountTx(litecoinspaceTx, address, accountId, exchangeRates, currency);
      const acceptThisTx = determineTxAcceptance(accountTx, fromAddress, toAddress, fromToCombinator);

      if (acceptThisTx) {
        accountTransactions.push(accountTx);
      }
    }

    return maybeAddMisttrackRisks({
      accountTransactions,
      address,
      apiUrl,
      withMisttrackRisks,
      chain: 'ltc',
      jwt: userJwt,
    });
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    nextPageExists = true;
    resetLitecoinspaceIterator();
  };

  const getAllowedFilters = () => {
    return [];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const getCreatedAt = (litecoinspaceTx) => {
  const epochTime = litecoinspaceTx?.status?.block_time;
  return new Date(epochTime * 1000);
};

const transformToAccountTx = (litecoinspaceTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'LTC';

  let type = 'transfer';
  const totalOwnInValue = (litecoinspaceTx?.vin ?? [])
    .filter(input => input.prevout.scriptpubkey_address === originatorAddress)
    .reduce((acc, input) => acc + input.prevout.value, 0);
  const totalOwnOutValue = (litecoinspaceTx?.vout ?? [])
    .filter(output => output.scriptpubkey_address === originatorAddress)
    .reduce((acc, input) => acc + input.value, 0);
  if (totalOwnInValue > totalOwnOutValue) {
    type = 'crypto withdrawal';
  } else if (totalOwnInValue < totalOwnOutValue) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(litecoinspaceTx),
    transaction_id: litecoinspaceTx.txid,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  (litecoinspaceTx?.vin ?? []).forEach(input => {
    const inputFrom = input.prevout.scriptpubkey_address;
    const inputMovement = {
      address: inputFrom,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: input.prevout.value / LTC_CURRENCY_FACTOR,
      fee: false,
      owned: inputFrom === originatorAddress,
    };
    accountTransaction.inputs.push(inputMovement);
  });

  (litecoinspaceTx?.vout ?? []).forEach(output => {
    const outputTo = output.scriptpubkey_address;
    const outputMovement = {
      address: outputTo,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: output.value / LTC_CURRENCY_FACTOR,
      fee: false,
      owned: outputTo === originatorAddress,
    };
    accountTransaction.outputs.push(outputMovement);
  });

  // Add fee
  const fee = litecoinspaceTx.fee / LTC_CURRENCY_FACTOR;
  // It does not matter if this is not correct in this case, ignore fee movements anyway
  const feePayerAddress = litecoinspaceTx.vin[0].prevout.scriptpubkey_address;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};

export default getLtcTxsLoader;
