import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import {
  determineTxAcceptance,
  getSimpleTransactionFee,
  getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx,
  maybeAddMisttrackRisks,
} from '../../../../utils/account-tx-parser';

const BNB_CURRENCY_FACTOR = 1000000000000000000;

const createBscScanTxIterator = (address, bscScanApiKey) => {
  let bscScanPageNumber = 0; // 0 because we increment it before loading a page
  let txsToProcessBuffer = [];
  let txsToProcessPointer = 0;
  let hasNextFlag = true;

  const next = async () => {
    if (txsToProcessPointer < txsToProcessBuffer.length) {
      const tx = txsToProcessBuffer[txsToProcessPointer];
      txsToProcessPointer += 1;
      return tx;
    }

    bscScanPageNumber += 1;
    const newTxsForBufferResponse = await axios.get(
      'https://api.bscscan.com/api',
      {
        params: {
          module: 'account',
          action: 'txlist',
          address: address,
          page: bscScanPageNumber,
          offset: 1000,
          sort: 'desc',
          startblock: 0,
          endblock: 99999999,
          apikey: bscScanApiKey,
        }
      }
    );
    if (newTxsForBufferResponse?.data?.result === undefined) {
      hasNextFlag = false;
      return;
    }
    const newTxsForBuffer = newTxsForBufferResponse?.data?.result;
    if (newTxsForBuffer.length === 0) {
      hasNextFlag = false;
      return;
    }

    txsToProcessPointer = 1;
    txsToProcessBuffer = newTxsForBuffer;
    return txsToProcessBuffer[0];
  };

  const hasNext = () => {
    return hasNextFlag;
  };

  const reset = () => {
    bscScanPageNumber = 0; // 0 because we increment it before loading a page
    txsToProcessBuffer = [];
    txsToProcessPointer = 0;
    hasNextFlag = true;
  };

  return { next, hasNext, reset };
};

const getBscTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  externalApiKey = undefined,
  fromToCombinator = { allHaveToMatch: true, orderMatters: true },
  loaderKey,
}) => {
  let nextPageExists = true;
  const bnbCurrencyPair = `bnb/${currency.toLowerCase()}`;
  const ethCurrencyPair = `eth/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};

  const { next: nextBscScanTx, hasNext: hasNextBscScanTx, reset: resetBscScanIterator } = createBscScanTxIterator(address, externalApiKey);

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([bnbCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['bnb'] = rates?.[bnbCurrencyPair] ?? 0;
      exchangeRates['eth'] = rates?.[ethCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    let accountTransactions = [];

    while (accountTransactions.length < pageSize) {
      if (!hasNextBscScanTx()) {
        nextPageExists = false;
        break;
      }
      const bscScanTx = await nextBscScanTx();
      if (bscScanTx === undefined) {
        nextPageExists = false;
        break;
      }

      const accountTx = transformToAccountTx(bscScanTx, address, accountId, exchangeRates, currency);
      const acceptThisTx = determineTxAcceptance(accountTx, fromAddress, toAddress, fromToCombinator);

      if (acceptThisTx) {
        accountTransactions.push(accountTx);
      }
    }

    return maybeAddMisttrackRisks({
      accountTransactions,
      address,
      apiUrl,
      withMisttrackRisks,
      chain: 'bnb',
      jwt: userJwt,
    });
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    nextPageExists = true;
    resetBscScanIterator();
  };

  const getAllowedFilters = () => {
    return [];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const getCreatedAt = (bscScanTx) => {
  const epochTime = bscScanTx.timeStamp;
  return new Date(epochTime * 1000);
};

const getTxFee = (bscScanTx) => {
  const gasUsed = Number(bscScanTx?.gasUsed ?? '0');
  const gasPrice = Number(bscScanTx?.gasPrice ?? '0');
  return (gasUsed * gasPrice) / BNB_CURRENCY_FACTOR;
};

const transformToAccountTx = (bscScanTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'BNB';

  originatorAddress = originatorAddress.toLowerCase();

  let type = 'transfer';
  const fromLower = bscScanTx?.from.toLowerCase();
  const toLower = bscScanTx?.to.toLowerCase();
  if (bscScanTx?.value !== '0') {
    if (fromLower === originatorAddress && toLower !== originatorAddress) {
      type = 'crypto withdrawal';
    } else if (fromLower !== originatorAddress && toLower === originatorAddress) {
      type = 'crypto deposit';
    }
  }

  const accountTransaction = {
    created_at: getCreatedAt(bscScanTx),
    transaction_id: bscScanTx.hash,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  const amount = (bscScanTx?.value ?? 0) / BNB_CURRENCY_FACTOR;

  const inputMovement = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const outputMovement = {
    address: toLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: amount,
    fee: false,
    owned: toLower === originatorAddress,
  };

  accountTransaction.inputs.push(inputMovement);
  accountTransaction.outputs.push(outputMovement);

  // Add fee
  const fee = getTxFee(bscScanTx);

  const feeInput = {
    address: fromLower,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: fromLower === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};

export default getBscTxsLoader;
