import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import {
  determineTxAcceptance,
  getSimpleTransactionFee,
  getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx,
  maybeAddMisttrackRisks,
} from '../../../../utils/account-tx-parser';

const BCH_CURRENCY_FACTOR = 100000000;

const createBlockchaincomTxIterator = (address) => {
  let txsToProcessBuffer = [];
  let txsToProcessPointer = 0;
  let hasNextFlag = true;
  let offset = 0;
  const pageSize = 50;

  const _maybeRemovePrefixes = (tx) => {
    tx.inputs = tx.inputs.filter(input => input.address !== null);
    tx.inputs = tx.inputs.map(input => {
      if (input.address.includes(':')) {
        return {
          ...input,
          address: input.address.split(':')[1]
        };
      }
      return input;
    });
    tx.outputs = tx.outputs.filter(output => output.address !== null);
    tx.outputs = tx.outputs.map(output => {
      if (output.address.includes(':')) {
        return {
          ...output,
          address: output.address.split(':')[1]
        };
      }
      return output;
    });
    return tx;
  };

  const next = async () => {
    if (txsToProcessPointer < txsToProcessBuffer.length) {
      const tx = txsToProcessBuffer[txsToProcessPointer];
      txsToProcessPointer += 1;
      return _maybeRemovePrefixes(tx);
    }

    const txIdsUrl = `https://api.blockchain.info/haskoin-store/bch/address/${address}/transactions?limit=${pageSize}&offset=${offset}`;
    const txIdsResponse = await axios.get(txIdsUrl);
    const txIds = (txIdsResponse?.data ?? []).map(txInfo => txInfo.txid);

    if (txIds.length === 0) {
      hasNextFlag = false;
      return;
    }

    const txsDetailsUrl = `https://api.blockchain.info/haskoin-store/bch/transactions?txids=${txIds.join(',')}`;
    const newTxsForBufferResponse = await axios.get(txsDetailsUrl);
    const newTxsForBuffer = newTxsForBufferResponse?.data;
    if (newTxsForBuffer === undefined || newTxsForBuffer.length === 0) {
      hasNextFlag = false;
      return;
    }

    offset += pageSize;

    txsToProcessPointer = 1;
    txsToProcessBuffer = newTxsForBuffer;
    return _maybeRemovePrefixes(txsToProcessBuffer[0]);
  };

  const hasNext = () => {
    return hasNextFlag;
  };

  const reset = () => {
    offset = 0;
    txsToProcessBuffer = [];
    txsToProcessPointer = 0;
    hasNextFlag = true;
  };

  return { next, hasNext, reset };
};

const getBchTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  externalApiKey = undefined,
  fromToCombinator = { allHaveToMatch: false, orderMatters: true },
  loaderKey,
}) => {
  let nextPageExists = true;
  const bchCurrencyPair = `bch/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};

  const { next: nextBlockchaincomTx, hasNext: hasNextBlockchaincomTx, reset: resetBlockchaincomIterator } = createBlockchaincomTxIterator(address);

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([bchCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['bch'] = rates?.[bchCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    let accountTransactions = [];

    while (accountTransactions.length < pageSize) {
      if (!hasNextBlockchaincomTx()) {
        nextPageExists = false;
        break;
      }
      const blockchaincomTx = await nextBlockchaincomTx();
      if (blockchaincomTx === undefined) {
        nextPageExists = false;
        break;
      }

      const accountTx = transformToAccountTx(blockchaincomTx, address, accountId, exchangeRates, currency);
      const acceptThisTx = determineTxAcceptance(accountTx, fromAddress, toAddress, fromToCombinator);

      if (acceptThisTx) {
        accountTransactions.push(accountTx);
      }
    }

    return await maybeAddMisttrackRisks({
      accountTransactions,
      address,
      apiUrl,
      withMisttrackRisks,
      chain: 'bch',
      jwt: userJwt,
    });
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    nextPageExists = true;
    resetBlockchaincomIterator();
  };

  const getAllowedFilters = () => {
    return [];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const getCreatedAt = (blockchaincomTx) => {
  const epochTime = blockchaincomTx?.time;
  return new Date(epochTime * 1000);
};

const transformToAccountTx = (blockchaincomTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const currency = 'BCH';

  let type = 'transfer';
  const totalOwnInValue = (blockchaincomTx?.inputs ?? [])
    .filter(input => input.address === originatorAddress)
    .reduce((acc, input) => acc + input.value, 0);
  const totalOwnOutValue = (blockchaincomTx?.outputs ?? [])
    .filter(output => output.address === originatorAddress)
    .reduce((acc, output) => acc + output.value, 0);
  if (totalOwnInValue > totalOwnOutValue) {
    type = 'crypto withdrawal';
  } else if (totalOwnInValue < totalOwnOutValue) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(blockchaincomTx),
    transaction_id: blockchaincomTx.txid,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  (blockchaincomTx?.inputs ?? []).forEach(input => {
    const inputMovement = {
      address: input.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: input.value / BCH_CURRENCY_FACTOR,
      fee: false,
      owned: input.address === originatorAddress,
    };
    accountTransaction.inputs.push(inputMovement);
  });

  (blockchaincomTx?.outputs ?? []).forEach(output => {
    const outputMovement = {
      address: output.address,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: output.value / BCH_CURRENCY_FACTOR,
      fee: false,
      owned: output.address === originatorAddress,
    };
    accountTransaction.outputs.push(outputMovement);
  });

  // Add fee
  const fee = blockchaincomTx.fee / BCH_CURRENCY_FACTOR;
  // It does not matter if this is not correct in this case, ignore fee movements anyway
  const feePayerAddress = blockchaincomTx.inputs[0].address;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress === originatorAddress,
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};

export default getBchTxsLoader;
