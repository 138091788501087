import { Address, NETWORK } from 'cashaddr';

const CHAIN_REGEXPS = [
  {
    ticker: 'btc',
    regex: /^(bc1|[13])[a-zA-HJ-NP-Z0-9]{25,59}$/,
    prefixRegex: /^(b|bc|bc1|1|3)[a-zA-HJ-NP-Z0-9]{0,59}$/,
    label: 'Bitcoin'
  },
  {
    ticker: 'eth',
    regex: /^0x[a-fA-F0-9]{40}$/,
    prefixRegex: /^(0|0x)[a-fA-F0-9]{0,40}$/,
    label: 'Ethereum'
  },
  { // Beware, this has to be later than 'eth'
    ticker: 'arbitrum',
    regex: /^0x[a-fA-F0-9]{40}$/,
    prefixRegex: /^(0|0x)[a-fA-F0-9]{0,40}$/,
    label: 'Arbitrum'
  },
  { // Beware, this has to be later than 'eth'
    ticker: 'bnb',
    regex: /^0x[a-fA-F0-9]{40}$/,
    prefixRegex: /^(0|0x)[a-fA-F0-9]{0,40}$/,
    label: 'Binance Smart Chain'
  },
  {
    ticker: 'trx',
    regex: /^T[A-Za-z1-9]{33}$/,
    prefixRegex: /^T[A-Za-z1-9]{0,33}$/,
    label: 'Tron'
  },
  {
    ticker: 'doge',
    regex: /^D[5-9A-HJ-NP-U][1-9A-HJ-NP-Za-km-z]{32}$/,
    prefixRegex: /^D[5-9A-HJ-NP-U]?[1-9A-HJ-NP-Za-km-z]{0,32}$/,
    label: 'Dogecoin'
  },
  {
    ticker: 'ada',
    regex: /^addr1[a-zA-HJ-NP-Z0-9]{25,99}$/,
    label: 'Cardano'
  },
  {
    ticker: 'bch',
    regex: /^((bitcoincash|bchreg|bchtest):)?(q|p)[a-zA-Z0-9]{41}$/,
    prefixRegex: /^(b|bi|bit|bitc|bitco|bitcoi|bitcoin|bitcoinc|bitcoinca|bitcoincas|bitcoincash|bc|bch|bcht|bchte|bchtes|bchtest|bchr|bchre|bchreg)?:?(q|p)?[a-zA-Z0-9]{0,41}$/,
    label: 'Bitcoin Cash'
  },
  {
    ticker: 'flow',
    regex: /^0x[a-fA-F0-9]{16}$/,
    label: 'Flow'
  },
  {
    ticker: 'ltc',
    regex: /^([LM3]{1}[a-zA-HJ-NP-Z1-9]{26,33}|ltc1[a-z0-9]{39,59})$/,
    prefixRegex: /^(L|M|3|ltc1)[a-zA-HJ-NP-Z1-9]{0,59}$/,
    label: 'Litecoin'
  },
  {
    ticker: 'neo',
    regex: /^N[0-9a-zA-Z]{33}$/,
    prefixRegex: /^N[0-9a-zA-Z]{0,33}$/,
    label: 'Neo'
  },
  {
    ticker: 'sol',
    regex: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/,
    prefixRegex: /^[1-9A-HJ-NP-Za-km-z]{0,44}$/,
    label: 'Solana'
  },
  {
    ticker: 'ton',
    regex: /^([EU])([Qf])[a-zA-Z0-9_-]{47}$/,
    prefixRegex: /^([EU])([Qf])[a-zA-Z0-9_-]{0,47}$/,
    label: 'Toncoin'
  },
  {
    ticker: 'xlm',
    regex: /^G[A-Z2-7]{55}$/,
    label: 'Stellar'
  },
  {
    ticker: 'xrp',
    regex: /^r[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    label: 'Ripple'
  },
  {
    ticker: 'near',
    regex: /^(([a-z\\d]+[\\-_])*[a-z\\d]+\\.)*([a-z\\d]+[\\-_])*[a-z\\d]+$/,
    label: 'Near'
  },
];

export const getChainByAddress = (address) => {
  const possibleChains = CHAIN_REGEXPS
    .filter(chainInfo => chainInfo.regex.test(address))
    .map(chainInfo => ({
      ticker: chainInfo.ticker,
      label: chainInfo.label
    }));
  return possibleChains[0];
};

export const genesisTimestampByChain = {
  'btc': 1231502400,
  'eth': 1438257600,
  'trx': 1532469600,
  'ada': 1506117600,
  'bch': 1501538400,
  'flow': 1590962400,
  'ltc': 1317938400,
  'near': 1588543200,
  'neo': 1391209200,
  'sol': 1584313200,
  'xlm': 1443564000,
  'xrp': 1338588000,
  'arbitrum': 1630360800,
  'bnb': 1598824800,
  'ton': 1572526800,
  'doge': 1386288000,
};

export const getPossibleChainsByAddressPrefix = (addressPrefix, allowedChains) => {
  let allCandidates = CHAIN_REGEXPS.filter(chainInfo => chainInfo.prefixRegex);
  if (allowedChains !== undefined) {
    allCandidates = allCandidates.filter(candidate => allowedChains.includes(candidate.ticker));
  }
  if (addressPrefix === '') {
    return allCandidates;
  }
  return allCandidates.filter(chainInfo => chainInfo.prefixRegex.test(addressPrefix));
};

export const preprocessAddress = (address, chain) => {
  if (chain.toLowerCase() !== 'bch') {
    return address;
  }
  try {
    return Address(NETWORK).toCashAddress(address).split(':')[1];
  } catch (err) {
    return undefined;
  }
};
