import styles from './merchant.module.scss';
import globalStyles from '../customer-details/style.module.scss';
import CustomerDetailsBox from '../customer-details/CustomerDetailsBox';
import { ReactComponent as SettingsIcon } from '../../assets/images/svg/settings.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/svg/trash-general.svg';
import { ReactComponent as CrossIcon } from '../../assets/images/svg/cross.svg';
import { ReactComponent as CheckmarkIcon } from '../../assets/images/svg/check-mark.svg';
import { useEffect, useState } from 'react';
import useDeposits from '../../services/deposits/deposits-service';
import { useParams } from 'react-router-dom';
import { valueExists } from '../../utils/common';
import CopyToClipboardWithIcon from '../../components/copy-to-clipboard-with-icon/CopyToClipboardWithIcon';
import { sliceWalletAddress } from '../customer-details/sliceWalletAddress';
import AddDestinationButton from '../../components/deposits/merchant/AddDestinationButton';
import AddDestinationModal from '../../components/deposits/merchant/AddDestinationModal';
import toast from 'react-hot-toast';
import { axiosErrorHandler } from '../../services/api/axios';


const Merchant = () => {
  const { getProjectDepositDestinations, deleteDepositDestination } = useDeposits();
  const { id } = useParams();

  const [currentDestinations, setCurrentDestinations] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);

  const handleDelete = async (currency, network) => {
    try {
      await deleteDepositDestination(id, currency, network);
    } catch (err) {
      toast.error(axiosErrorHandler(err), 'Error deleting destination, please try again later');
      return;
    }
    setCurrentDestinations(prevDestinations => prevDestinations.filter(
      destination => destination.currency !== currency || destination.network !== network
    ));
    toast.success('Destination deleted successfully');
  };

  const afterAdd = (destinationData) => {
    setCurrentDestinations(prevDestinations => [...prevDestinations, destinationData]);
  };

  useEffect(() => {
    const getDepositDestinations = async () => {
      const destinations = await getProjectDepositDestinations(id);
      const parsedDestinations = [];
      Object.entries(destinations).forEach(([currency, currencyInfo]) => {
        Object.entries(currencyInfo).forEach(([network, networkInfo]) => {
          parsedDestinations.push({
            currency,
            network,
            ...networkInfo,
          });
        });
      });
      setCurrentDestinations(parsedDestinations);
    };
    getDepositDestinations();
  }, []);

  return <div>
    {addModalOpen && <AddDestinationModal handleClose={() => setAddModalOpen(false)} projectId={id} afterAdd={afterAdd} />}
    <div className={globalStyles.customers}>
      <CustomerDetailsBox
        title='Your merchant data'
        icon={<SettingsIcon/>}
        buttons={<AddDestinationButton handleClick={() => setAddModalOpen(true)} />}
      >
        <div className={styles.container}>
          <div className={styles.depositInfoTableContainer}>
            <div className={styles.tableHeader}>
              <div>Currency</div>
              <div>Network</div>
              <div>Address</div>
              <div>Beneficiary</div>
              <div>Country</div>
              <div>Institution</div>
              <div>Delete</div>
            </div>

            {currentDestinations.map(destinationInfo => {
              return <div className={styles.tableRow} key={`${destinationInfo?.currency}-${destinationInfo?.network}`}>
                <div>
                  {destinationInfo?.currency}
                </div>
                <div>
                  {destinationInfo?.network}
                </div>
                <div>
                  <CopyToClipboardWithIcon copyValue={destinationInfo?.addresses?.[0]} tooltipDisplayedData={destinationInfo?.addresses?.[0]}>
                    {sliceWalletAddress(destinationInfo?.addresses?.[0], 8)}
                  </CopyToClipboardWithIcon>
                </div>
                <div>
                  {destinationInfo?.travel_rule_beneficiary_name}
                </div>
                <div>
                  {destinationInfo?.travel_rule_country}
                </div>
                <div className={styles.icon}>
                  {valueExists(destinationInfo?.travel_rule_institution) ? <CheckmarkIcon /> : <CrossIcon />}
                </div>
                <div className={styles.deleteIcon}>
                  <TrashIcon onClick={() => handleDelete(destinationInfo.currency, destinationInfo.network)} />
                </div>
              </div>;
            })}
          </div>
        </div>
      </CustomerDetailsBox>
    </div>
  </div>;
};

export default Merchant;
