import { toast } from 'react-hot-toast';
import { axiosErrorHandler } from '../services/api/axios';
import { NETWORK, Address } from 'cashaddr';

export const handleQuickRiskError = (error) => {
  const reason = error?.response?.data;
  const errorsToDisplay = [
    {
      source: 'Error getting balance',
      detail: reason?.balanceErr,
    },
    {
      source: 'Error getting risk result',
      detail: reason?.riskResultErr,
    },
    {
      source: 'Error getting risk overview',
      detail: reason?.riskOverviewErr,
    }
  ].filter(error => error.detail !== undefined && error.detail !== '%!s(<nil>)');
  if (errorsToDisplay.length > 0) {
    let handleApiErrorPopup = true;
    errorsToDisplay.forEach(error => {
      toast.error(`${error.source} - ${error.detail}`, {
        duration: 4000 * errorsToDisplay.length
      });
      handleApiErrorPopup = false;
    });
  } else {
    toast.error(axiosErrorHandler(error, 'Error getting risk data. Please try again later'));
  }
};

export const getAddressesToRiskExplore = (address, chain) => {
  if (chain !== 'bch') {
    return [address];
  }
  const cashAddress = Address(NETWORK).toCashAddress(address);
  const cashAddressNoPrefix = cashAddress.split(':')[1];
  const legacyAddress = Address(NETWORK).toLegacyAddress(address);
  return [legacyAddress, cashAddress, cashAddressNoPrefix];
};
