import axios from 'axios';
import getExchangeRates from '../../../api/exchange-rates';
import { sleep, valueExists } from '../../../../utils/common';
import { filterTypes } from '../../../../components/filters/ConsolidatedTransactionsFilters';
import { axiosErrorHandler } from '../../../api/axios';
import { toast } from 'react-hot-toast';
import {
  determineTxAcceptance,
  getSimpleTransactionFee,
  getTransactionAmount,
  getTransactionTotal,
  maybeAddConvertedValuesToTx,
  maybeAddMisttrackRisks
} from '../../../../utils/account-tx-parser';

const getSolTxsLoader = ({
  address,
  accountId,
  withMisttrackRisks = true,
  currency = 'usd',
  pageSize = 50,
  apiUrl,
  userJwt,
  fromAddress = undefined,
  toAddress = undefined,
  fromToCombinator = { allHaveToMatch: false, orderMatters: true },
  externalApiKey = undefined,  // Helius API key, but general name to conform to loaders interface (virtual)
}) => {
  let oldestFetchedSignature = undefined;
  let nextPageExists = true;
  const solCurrencyPair = `sol/${currency.toLowerCase()}`;
  const usdtCurrencyPair = `usdt/${currency.toLowerCase()}`;
  const exchangeRates = {};

  const loadNextPage = async (filters) => {
    if (Object.keys(exchangeRates).length === 0) {
      const rates = await getExchangeRates([solCurrencyPair, usdtCurrencyPair], apiUrl, userJwt);
      exchangeRates['sol'] = rates?.[solCurrencyPair] ?? 0;
      exchangeRates['usdt'] = rates?.[usdtCurrencyPair] ?? 0;
    }

    if (filters) {
      const {
        accountsFilterParam,
        digitalAssetsFilterParam,
      } = filters;

      if (valueExists(accountsFilterParam)) {
        if (!accountsFilterParam.split(',').includes(accountId)) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
      if (valueExists(digitalAssetsFilterParam)) {
        if (!digitalAssetsFilterParam.split(',').includes('SOL')) {
          nextPageExists = false;
          return {
            nextPage: [],
            loadError: undefined,
          };
        }
      }
    }

    const page = []; // Due to from/to filtering, multiple requests might be needed to fill a single page
    let remainingRequests = 10; // So that we don't run out of credits. This is 10 * 100 = 1000 txs
    const url = `https://api.helius.xyz/v0/addresses/${address}/transactions`;

    while (page.length < pageSize) {
      const params = {
        'api-key': externalApiKey,
        limit: 100,
      };
      if (valueExists(oldestFetchedSignature)) {
        params.before = oldestFetchedSignature;
      }

      let data = [];
      let wasError;
      try {
        wasError = false;
        const response = await axios.get(
          url,
          { params }
        );
        data = response?.data ?? [];
      } catch (err) {
        // Ignore too many requests errors
        if (err?.response?.status !== 429) {
          toast.error(axiosErrorHandler(err, 'Error getting Solana transactions from explorer, data might be incomplete'));
        } else {
          await sleep(2000);
        }
        wasError = true;
      }

      if (data.length < pageSize) {
        nextPageExists = false;
      }

      // Do not break if there was an error (retry instead)
      if (data.length === 0 && !wasError) {
        break;
      }

      // Move cursor
      if (data.length > 0) {
        const oldestSignature = data.slice(-1)?.[0]?.signature;
        if (oldestSignature) {
          oldestFetchedSignature = oldestSignature;
        }
      }

      const accountTransactions = data
        .map(heliusTx => transformToAccountTx(heliusTx, address, accountId, exchangeRates, currency))
        .filter(accountTx => determineTxAcceptance(accountTx, fromAddress, toAddress, fromToCombinator));

      for (const tx of accountTransactions) {
        if (page.length < pageSize) {
          page.push(tx);
        } else {
          oldestFetchedSignature = tx.transaction_id;
          nextPageExists = true;
          break;
        }
      }

      remainingRequests -= 1;
      if (remainingRequests <= 0) {
        break;
      }
    }

    return maybeAddMisttrackRisks({
      accountTransactions: page,
      address,
      apiUrl,
      withMisttrackRisks,
      chain: 'sol',
      jwt: userJwt,
    });
  };

  const hasNextPage = () => {
    return nextPageExists;
  };

  const reset = () => {
    oldestFetchedSignature = undefined;
    nextPageExists = true;
  };

  const getAllowedFilters = () => {
    return [filterTypes.ACCOUNTS, filterTypes.ASSETS];
  };

  return { loadNextPage, hasNextPage, reset, getAllowedFilters };
};

const getCreatedAt = (transaction) => {
  const epochTime = transaction.timestamp;
  return new Date(epochTime * 1000);
};

const transformToAccountTx = (heliusTx, originatorAddress, accountId, exchangeRates, fiatCurrency) => {
  const SOL_CURRENCY_FACTOR = 1000000000;
  const currency = 'SOL';

  let type = 'transfer';
  const originatorPaysFee = heliusTx.feePayer.toLowerCase() === originatorAddress.toLowerCase();
  const toOriginatorOutputExists = [...(heliusTx.nativeTransfers ?? []), ...(heliusTx.tokenTransfers ?? [])].some(
    transferInfo => transferInfo?.toUserAccount.toLowerCase() === originatorAddress.toLowerCase()
  );
  if (originatorPaysFee && !toOriginatorOutputExists) {
    type = 'crypto withdrawal';
  } else if (!originatorPaysFee && toOriginatorOutputExists) {
    type = 'crypto deposit';
  }

  const accountTransaction = {
    created_at: getCreatedAt(heliusTx),
    transaction_id: heliusTx.signature,
    account_id: accountId,
    type: type,
    inputs: [],
    outputs: [],
    categories: [],
    hideValue: false,
  };

  // SOL transfers
  heliusTx.nativeTransfers.forEach(solTransfer => {
    const inputAddress = solTransfer.fromUserAccount;
    const amount = solTransfer.amount / SOL_CURRENCY_FACTOR;

    const inputMovement = {
      address: inputAddress,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: amount,
      fee: false,
      owned: inputAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    const outputAddress = solTransfer.toUserAccount;
    const outputMovement = {
      address: outputAddress,
      currency: currency,
      currency_name: currency,
      currency_symbol: currency,
      value: amount,
      fee: false,
      owned: outputAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    accountTransaction.inputs.push(inputMovement);
    accountTransaction.outputs.push(outputMovement);
  });

  // SPL transfers
  heliusTx.tokenTransfers.forEach(tokenTransfer => {
    const fromAddress = tokenTransfer.fromUserAccount;
    const toAddress = tokenTransfer.toUserAccount;
    const currencyString = `${currency}:${tokenTransfer.mint}`;
    const amount = tokenTransfer.tokenAmount;

    const splInput = {
      address: fromAddress,
      currency: currencyString,
      currency_name: currencyString,
      currency_symbol: currencyString,
      value: amount,
      fee: false,
      owned: fromAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    const splOutput = {
      address: toAddress,
      currency: currencyString,
      currency_name: currencyString,
      currency_symbol: currencyString,
      value: amount,
      fee: false,
      owned: toAddress.toLowerCase() === originatorAddress.toLowerCase(),
    };

    accountTransaction.inputs.push(splInput);
    accountTransaction.outputs.push(splOutput);
  });

  // Add fee
  const fee = heliusTx.fee / SOL_CURRENCY_FACTOR;
  const feePayerAddress = heliusTx.feePayer;

  const feeInput = {
    address: feePayerAddress,
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: false,
    owned: feePayerAddress.toLowerCase() === originatorAddress.toLowerCase(),
  };

  const feeOutput = {
    address: '',
    currency: currency,
    currency_name: currency,
    currency_symbol: currency,
    value: fee,
    fee: true,
    owned: false,
  };

  accountTransaction.inputs.push(feeInput);
  accountTransaction.outputs.push(feeOutput);

  maybeAddConvertedValuesToTx(accountTransaction, exchangeRates, currency, fiatCurrency);

  accountTransaction.fee = getSimpleTransactionFee(accountTransaction, fee);
  accountTransaction.total = getTransactionTotal(accountTransaction);
  accountTransaction.amount = getTransactionAmount(accountTransaction);

  return accountTransaction;
};

export default getSolTxsLoader;
