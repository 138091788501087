import styles from './add-destination-button.module.scss';
import MainButton from '../../buttons/MainButton';
import { ReactComponent as PlusIcon } from '../../../assets/images/svg/plus-icon.svg';

const AddDestinationButton = ({ handleClick }) => {
  return <MainButton
    onClick={handleClick}
    variant='button-black'
    label='Add destination'
    icon={<PlusIcon />}
    extraClasses={styles.addButton}
  />;
};

export default AddDestinationButton;
